<template>
	<div>
		<!-- 图片 -->
		<div>
			<img class="in_02 w100 display-block" src="@/assets/img/in_02.png" alt="">
		</div>

		<div class="center flex-column bg-white">
			<div class="w1200 h74 justify-content-center-between">
				<div class="h74 justify-content-center-between search-box-left">
					<input placeholder="请输入您想搜索的内容" v-model="content" type="text"
						class="fz16 co-333333 flex-1 search-left-input" @confrim="toSearch" />
					<img class="display-block search-left-icon marl20 cure-pointer" v-if="!isSearch" @click="toSearch"
						src="@/assets/img/search_icon.png" alt="" />
					<img class="display-block search-left-icon marl20 cure-pointer" v-if="isSearch" @click="clearSearch"
						src="@/assets/img/calcel.png" alt="" />
				</div>

				<div class="align-items-center" v-if="isSearch">
					<div class="title-item center cure-pointer fz14" v-for="(item, i) in typeList" :key="i"
						:class="typeIndex == i ? 'bg-DCFF03 co-010085' : 'co-333333'" @click="typeChoose(i)">
						{{item}}
					</div>
				</div>
			</div>

			<!-- 列表 -->
			<div class="w100   align-items-center flex-column">
				<div class="flex">
					<div class="w1200 bg-white">
						<div class="justify-content-center-between paddt20 paddb40 ">
							<div class="align-items-center">
								<div class="fz20 co-333333">热门搜索</div>
								<div class="title-item center cure-pointer fz14" v-for="(item, i) in titleList" :key="i"
									:class="[titleIndex == item.id ? 'bg-DCFF03 co-010085' : 'co-333333', i == 0 ? 'marl32' : '']"
									@click="titleChoose(i)">{{item.name}}</div>
							</div>
						</div>
						<div v-if="!isSearch">
							<Competition :list="list"></Competition>
						</div>

						<div class="display-flex w1200 flex-column" v-if="isSearch">
							<div class="" v-if="picture.length > 0&&(typeIndex == 1 || typeIndex == 0)">
								<div class="fz30 co-333333 font-blod marb30">文章</div>
								<Inspiration :list="picture"></Inspiration>
							</div>
							<div class="" v-if="resource.length > 0&&(typeIndex == 2 || typeIndex == 0)">
								<div class="fz30 co-333333 font-blod marb30">素材</div>
								<Material :list="resource"></Material>
							</div>
							<div class="" v-if="course.length > 0&&(typeIndex == 3 || typeIndex == 0)">
								<div class="fz30 co-333333 font-blod marb30">课程</div>
								<ClassSelect :list="course"></ClassSelect>
							</div>
							<div class="" v-if="competition.length > 0&&(typeIndex == 4 || typeIndex == 0)">
								<div class="fz30 co-333333 font-blod marb30">竞赛</div>
								<Competition :list='competition'></Competition>
							</div>
							<div class="" v-if="teacher.length > 0&&(typeIndex == 5 || typeIndex == 0)">
								<div class="fz30 co-333333 font-blod marb30">名师</div>

								<teacherlist :list='teacher'></teacherlist>
							</div>
							<div class="" v-if="school.length > 0&&(typeIndex == 6 || typeIndex == 0)">
								<div class="fz30 co-333333 font-blod marb30">院校</div>
								<Competition :list="school" type="2"></Competition>
							</div>
							<div class="" v-if="agency.length > 0&&(typeIndex == 7 || typeIndex == 0)">
								<div class="fz30 co-333333 font-blod marb30">机构</div>
								<SincerityList :list="agency"></SincerityList>
							</div>
						</div>
						<loadMore :state="state" loading_icon></loadMore>
						<div class="center paddt54" v-if="!isSearch">
							<el-pagination background  class="center "  @current-change="handleCurrentChange"
							    layout="prev, pager, next, jumper" :current-page="page"   :total="maxnum" :page-size="size">
							</el-pagination>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
	<div v-loading.fullscreen.lock="fullscreenLoading"></div>
</template>

<script>
	export default {
		name: "SearchPage",
		data() {
			return {

				titleList: [], // 热门搜索标题
				titleIndex: '', // 筛选标题选中
				typeList: ['综合', '文章', '素材', '课程', '竞赛', '名师', '院校', '机构'], // 搜索模块
				isSearch: false,
				typeIndex: 0, // 搜索模块选中
				pull: false, // 是否下拉
				selectList: ['综合排序', '最新', '最热'], // 下拉列表
				seletIndex: 0, // 下拉选中
				historyList: [],
				content: '', //搜索关键词
				picture: [], //研图社
				resource: [], //素材
				course: [], //课程
				competition: [], //竞赛
				teacher: [], //名师
				school: [], //学校
				agency: [], //机构
				fullscreenLoading: true,
				state: 0,
				list: [],
				page: 0,
				loading: false,
				maxnum: '', //总条数
				size: 12,
			}
		},
		mounted() {

			//热门搜索
			this.$req({
				method: 'get',
				url: '/api/tags/recommend',
				success: res => {

					this.titleList = res
				},
				fail: error => {}
			});

			this.getlist();
		},
		methods: {
			handleCurrentChange(e){
				// 页面变化
				console.log(e,"页数变化了")
				this.$req({
					method: 'get',
					data: {
						page: e,
						size: this.size,
					},
					url: '/api/recommend/found',
					success: res => {
				
						let list = res;
						this.list = list;
						if (this.list.length == 0) {
							this.state = 3;
						}
				
						this.loading = false;
						this.fullscreenLoading = false;
					},
					fail: error => {
						if (this.list.length == 0) {
							this.state = 3;
						} else {
							this.state = 2;
						}
						this.loading = false;
						this.fullscreenLoading = false;
					}
				});
			},
			getlist() {
				this.$req({
					method: 'get',
					data: {
						show_page: 1,

					},
					url: '/api/recommend/found',
					success: res => {
						console.log(res, '总条数')
						this.maxnum = res;
						// this.size = this.maxnum
						if (this.loading || this.state > 1) return;
						let page = parseInt(this.page) + 1;
						this.loading = true;
						this.state = 1;
						let size = 20;
						console.log(this.size)
						this.$req({
							method: 'get',
							data: {
								page: page,
								size: this.size,
							},
							url: '/api/recommend/found',
							success: res => {

								let list = res;
								console.log(list, '搜索数据')
								this.list = list;
								if (list.length == size) {
									this.state = 1;
								} else if (list.length > 0) {
									this.state = 2;
								} else if (this.list.length == 0) {
									this.state = 3;
								}

								this.loading = false;
								this.fullscreenLoading = false;
							},
							fail: error => {
								if (this.list.length == 0) {
									this.state = 3;
								} else {
									this.state = 2;
								}
								this.loading = false;
								this.fullscreenLoading = false;
							}
						});
					},
					fail: error => {

					}
				});

			},
			toSearch(e) {

				if (!this.content) return
				this.getlist1()
			},
			clearSearch(){
				this.content = ''
				this.isSearch = false
				this.titleIndex = ''
				this.state = 0
				this.handleCurrentChange(1)
			},
			getlist1() {
				this.state = 1;
				this.$req({
					method: 'get',
					url: '/api/search',
					data: {
						search: this.content,
					},
					success: res => {
						this.picture = res.picture
						this.resource = res.resource
						this.course = res.course
						this.competition = res.competition
						this.teacher = res.teacher
						this.school = res.school
						this.agency = res.agency
						this.isSearch = true;
						if (this.picture.length == 0 && this.resource.length == 0 && this.course.length == 0 &&
							this.competition.length == 0 && this.teacher.length == 0 &&
							this.school.length == 0 && this.agency.length == 0) {
							this.state = 3;
						}
					},
					fail: error => {

					}
				});

				this.state = 0;
			},
			// 搜索模块选中
			typeChoose(e) {
				this.typeIndex = e;
				console.log(this.typeIndex)
			},
			// 筛选标题选中
			titleChoose(e) {
				this.titleIndex = this.titleList[e].id;
				this.content = this.titleList[e].name
				this.getlist1()
			},
			// 点击下拉
			pullClick() {
				this.pull = !this.pull
			},
			// 下拉选中
			selectChoose(e) {
				this.seletIndex = e
			}
		}
	}
</script>

<style lang="less" scoped>
	.search-box-left {
		width: 530px;
		height: 34px;
		background: #F7F7F7;
		opacity: 1;
		border-radius: 4px;
		padding: 0 12px;
	}

	.search-left-input {
		outline: none;
		border: 0;
		background: #F7F7F7;
	}

	.search-left-icon {
		width: 18px;
		height: 18px;
	}

	.in_02 {
		height: 200px;
	}

	/* 筛选标题 */
	.title-item {
		min-width: 70px;
		padding: 0 5px;
		height: 28px;
		border-radius: 3px;
	}

	/* 排序 */
	.sort {
		width: 90rpx;
		height: 28px;
		border: 1px solid #010085;
		border-radius: 3px;
		padding: 0 8px;

		.pull-pic {
			width: 14px;
			height: 14px;
			transition: all 0.3s;
		}

		.rotate {
			transform: rotate(180deg);
		}

		.triangle {
			width: 27px;
			height: 15px;
			top: 28px;
			right: 0;
			z-index: 100;
		}

		.pull-box {
			width: 140px;
			background: #FFFFFF;
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
			top: 43px;
			border-radius: 8px;
			left: 0;
			overflow: hidden;
			transition: all 0.3s;

			.check_icon {
				width: 15px;
				height: 15px;
			}
		}
	}

	input::-webkit-input-placeholder {
		color: #999999;
		font-size: 16px;
	}

	// 改变组件样式
	.el-pager li {
		font-size: 14px !important;
		width: 40px !important;
		height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
		line-height: 40px !important;
		padding: 0 !important;
		color: #999999 !important;
	}

	.btn-prev,
	.btn-next {
		width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
	}

	.el-pager .active {
		background: #DCFF03 !important;
		color: #010085 !important;
		border: 1px solid #DCFF03 !important;
	}
</style>
